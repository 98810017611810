document.addEventListener('DOMContentLoaded', () => {
	const portfolio_items = document.querySelectorAll<HTMLDivElement>('.portfolio-loop .item');

	portfolio_items.forEach((item) => {
		let index = 0;

		const slider = item.querySelector<HTMLDivElement>('.slider-holder')!;
		const images = item.querySelectorAll('.property-loop-image');
		const pagination = item.querySelector('.portfolio-pagination')!;
		const prevButton = pagination.children[0];
		const nextButton = pagination.children[1];
		// const images = item.querySelectorAll('.property-loop-image');

		const refresh = () => {
			slider.style.setProperty('--slide-position', index.toString());
		};

		prevButton.addEventListener('click', () => {
			index = Math.max(index - 1, 0);
			refresh();
		});
		nextButton.addEventListener('click', () => {
			index = Math.min(index + 1, images.length - 1);
			refresh();
		});
	});
});
