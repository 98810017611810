document.addEventListener('DOMContentLoaded', function(e) {
    let forms = document.querySelectorAll('form#contact-form,form#request-maintenace-form');
    if (forms.length > 0) {
        forms.forEach(form => {
            var status = form.querySelector('.form-status');
            async function handleSubmit(event) {
                event.preventDefault();
                let statusHead = status.querySelector('h6');
                let statusBody = status.querySelector('p');
                let button = form.querySelector('input[type=submit]');
                var data = new FormData(event.target);
                button.value = "Submitting...";
                fetch(event.target.action, {
                  method: form.method,
                  body: data,
                  headers: {
                      'Accept': 'application/json'
                  }
                }).then(response => {
                  status.classList.add('open');
                  statusHead.innerText = "Thank you!";
                  statusBody.innerText = "We will be in touch soon regarding your submission.";
                  form.reset()
                  button.value = "Submitted!";
                }).catch(error => {
                  status.classList.add('open');
                  statusHead.innerText = "Oops!"
                  statusBody.innerText = "There was a problem submitting your form. Please try again, or reach out to us for assistance if the problem persists."
                  button.value = "Submit";
                });
            }

            form.addEventListener("submit", handleSubmit);

            status.addEventListener('click', function(e) {
                if (e.target.closest('.card') == null) {
                    status.classList.remove('open');
                }
            })
        })
    }
})
