window.addEventListener('DOMContentLoaded', ()=>{
    const maintenaceForm = document.querySelector('#request-maintenace-form')
    if(maintenaceForm){
        const arrowDown = maintenaceForm.querySelector('.arrow-down')
        const termsContent = maintenaceForm.querySelector('.terms-content')
        if(termsContent){
            termsContent.setAttribute('style', `--tooltip-height: ${termsContent.scrollHeight}px`)

            window.addEventListener('resize', ()=>{
                termsContent.style.height = 'auto'
                termsContent.setAttribute('style', `--tooltip-height: ${termsContent.scrollHeight}px`)
            })

            if(arrowDown){
                arrowDown.addEventListener('click', ()=>{
                    termsContent.classList.toggle('active')
                })
            }
        }
    }
})